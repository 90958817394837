<script>
import ButterCMSService from "@/services/ButterCMSService";
export default {
  name: "blog-post",
  data() {
    this.butterService = new ButterCMSService();
    return {
      post: {
        data: {
          author: {
            first_name: "",
            last_name: "",
          },
          title: "",
          body: "",
        },
        meta: {
          previous_post: {
            slug: "",
            title: "",
          },
          next_post: {
            slug: "",
            title: "",
          },
        },
        postrender: ""
      },
    };
  },
  methods: {
    getPost() {
      this.butterService.getPostBySlug(this.$route.params.slug).then((res) => {
        this.post = res;
        this.post.postrender = res.data.body;
      });
    },
    back() {
      this.$router.push(`/blog`);
    },
    nextPost() {
      this.$router.push(`/blog/${this.post.meta.next_post.slug}`);
    },
    previousPost() {
      this.$router.push(`/blog/${this.post.meta.previous_post.slug}`);
    },
  },
  created() {
    this.getPost();
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getPost();
      },
    },
  },
};
</script>

<template>
  <div id="blog-post">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-6 is-vertical">
          <div class="container blogpost">
            <h1 class="title">{{ post.data.title }}</h1>
            <h4>
              <!-- By: {{ post.data.author.first_name }} {{ post.data.author.last_name }} -->
              By: Allison Twarog
            </h4>
            <br />
            <br />
            <!-- {{ post.postrender }} -->
            <!-- <div v-text="post.postrender"></div> -->
            <div class="content" v-html="post.postrender"></div>
            <!-- <p>As I'm sure you've seen in my bio, my name is Alli! I have a 13 month old son named Linden. My husband and I had Linden right in the midst of the pandemic which has definitely been interesting (future post about that to come soon, so be on the lookout).</p> <p>Just to introduce myself, I'll tell you a few random facts about me:</p> <ul> <li>I have three cats named Salem, Moon and Primrose (they're a huge pain in my ass, but I couldn't live without them) - and Linden is allergic to cats which makes it far more interesting</li> <li>I have chronic vestibular migraines which present themselves as vertigo - my medication keeps it under control so that I can fully function (most days) which is a blessing</li> <li>My favorite food ever is my mom's homemade baked ziti</li> <li>I have two older brothers, and am super close with my whole family!</li> <li>I'm frequently told I have no filter, and that I should learn to not say everything that comes to mind.... yikes</li> <li>I have a passion for infant sleep training (I will have a sleep training program up and running within the next couple of months)</li> <li>I'm terrified of open water</li> <li>I HATE bacon and croutons</li> </ul> <p>Now, it's your turn to tell me about you! Introduce yourself in the comments and tell me a little something about yourself. I can't wait to get to know all of you!</p> -->
          </div>
        </div>
        <div class="tile is-4 is-vertical">
          <div class="blog-button-bar">
            <b-button
              class="blog-post-button"
              label="Back"
              type="is-light"
              size="is-small"
              @click="back"
            />
            <!-- <b-button
              class="blog-post-button"
              v-if="post.meta.previous_post"
              label="Previous Post"
              type="is-light"
              size="is-small"
              @click="previousPost"
            />
            <b-button
              class="blog-post-button"
              v-if="post.meta.next_post"
              label="Next Post"
              type="is-light"
              size="is-small"
              @click="nextPost"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>