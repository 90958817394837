<template>
  <div class="header-img-container" id="secondary-header">
    <div class="container">
      <br />
      <div class="header-logo">
        <h1 class="header-title">Uncensored Mama</h1>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
// import BlogCarousel from '@/components/BlogHeaderCarousel'
export default {
  name: "layout-header",
  components: {
    //   BlogCarousel
  },
};
</script>

<style lang="scss">
</style>