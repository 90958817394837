<template>
  <div id="nav">
    <b-navbar class="navbar" spaced shadow fixed-top centered>
      <template slot="brand">
        <b-navbar-item>
          <router-link to="/"><img src="@/assets/logoalli.png" /></router-link>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item>
          <router-link to="/blog">Blog</router-link>
        </b-navbar-item>
        <b-navbar-item>
          <router-link to="/contact">Contact</router-link>
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="a" href="https://www.instagram.com/bonobotwarog/?hl=en">
          <font-awesome-icon :icon="['fab', 'instagram']" />
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "layout-nav",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
