<template>
  <div id="blog-home">
    <div class="title">Hi I'm Alli!</div>
    <b-image
      :src="require('@/assets/allipreg.png')"
      alt="Image Missing"
      ratio="4by4"
      :rounded="true"
    ></b-image>
    <br />
    <!-- <div class="search-form">
      <b-field>
        <div class="search-input">
          <b-input placeholder="Search..." type="search"> </b-input>
        </div>
      </b-field>
    </div> -->
    <br />
    <div class="funfacts-widget">
      <p class="funfacts-title">Hi! Welcome to my blog. My name is Alli, and I have a one year old son named Linden with my husband, Justin.</p>
      <br />
      <p class="funfacts-title">This mommy blog is a bit different than the others you might find. I came up with the name Uncensored Mama because that’s exactly what my posts will be - uncensored.</p>
      <br />
      <p class="funfacts-title">Babies are hard. Sometimes being a mom sucks and THAT’S OKAY. I won’t sugar coat anything, this is all about the nitty gritty that I wish I knew prior to becoming a mama. I feel like I would’ve been way more prepared had I been able to find more information out there that is raw and real. Being a parent isn’t always sunshine and rainbows, but it is so beyond worth it.</p>
      <br />
      <!-- <ul class="funfacts-list">
        <br />
        <li>I am a mother of a 9 month old!</li>
        <br />
        <li>I enjoy hiking, music and reading!</li>
        <br />
        <li>I love horror movies!</li>
        <br />
      </ul> -->
    </div>
    <br />
    <!-- <div class="funfacts-widget">
      <p class="funfacts-title">Suggested Third Party Resources</p>
      <ul class="funfacts-list">
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Motherhood Get Help</a></li>
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Assistance For PostPartum</a></li>
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Top Foods For Kids</a></li>
        <br />
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "blog-about-me",
  data() {
    return {
      page_title: "Blog",
      posts: [],
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss">
</style>