import Butter from 'buttercms'
import Network from './abstract/Network'
export default class ButterCMSService extends Network {
    constructor(apiKey = process.env.VUE_APP_BUTTER_API_KEY, mode = true) {
        super()
        this.butterClient = Butter(apiKey, mode);
    }
    listPosts = (page = 1, page_size = 3, exclude_body = true) => {
        return this.butterClient.post.list({
            page: page,
            page_size: page_size,
            exclude_body: exclude_body
        }).then((res) => {
            // console.log(res.data)
            return res.data ? res.data : null
        }).catch((err) => {
            console.log(err)
            return err
        })
    }
    getPostBySlug = (slug) => {
        if (!slug) {
            throw "slug is required"
        }
       return this.butterClient.post.retrieve(
            slug
        ).then((res) => {
            // console.log(res.data)
            return res.data ? res.data : null
        }).catch((err) => {
            console.log(err)
            return err
        })
    }
}
