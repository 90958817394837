<script>
import BlogAboutMe from "@/components/BlogAboutMe.vue";
import ButterCMSService from "@/services/ButterCMSService";
export default {
  name: "blog-home",
  components: {
    BlogAboutMe,
  },
  data() {
    this.butterService = new ButterCMSService();
    return {
      page_title: "Blog Posts",
      posts: [],
      state: {
        page: 1,
        previousPage: false,
        nextPage: false,
      },
    };
  },
  methods: {
    getPosts() {
      this.butterService.listPosts(this.state.page).then((res) => {
        this.posts = res.data;
        this.state.nextPage = res.meta.next_page ? res.meta.next_page : false;
        this.state.previousPage = res.meta.previous_page
          ? res.meta.previous_page
          : false;
      });
    },
    nextPage() {
      this.state.page = this.state.page + 1;
      // console.log(this.state)
      this.getPosts();
      window.scrollTo(0,0)
    },
    previousPage() {
      this.state.page = this.state.page - 1;
      // console.log(this.state)
      this.getPosts();
      window.scrollTo(0,0)
    },
  },
  created() {
    this.getPosts();
  },
};
</script>

<template>
  <div id="blog-home">
    <p class="blog-title">{{ page_title }}</p>
    <br />
    <br />
    <div class="container">
      <div class="tile is-ancestor">
        <!-- All other tile elements -->
        <div class="tile is-6 is-vertical">
          <div v-for="(post, index) in posts" :key="post.slug + '_' + index">
            <router-link :to="'/blog/' + post.slug">
              <div class="card-custom">
                <div class="card-image">
                  <figure class="image is-8x12">
                    <img
                      v-if="post.featured_image"
                      :src="post.featured_image"
                      alt=""
                    />
                    <img
                      v-else
                      src="http://via.placeholder.com/250x250"
                      alt=""
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left"></div>

                    <div class="media-content">
                      <!-- <p>By: Allison Twarog</p>
                      <br /> -->
                    </div>
                  </div>

                  <div class="content">
                    <h2>{{ post.title }}</h2>
                    <p>{{ post.summary }}</p>
                    <br />
                    <time datetime="2016-1-1">{{ post.published.split('T')[0].split('-')[1] }} - {{ post.published.split('T')[0].split('-')[2] }}</time>
                  </div>
                </div>
              </div>
              <br />
            </router-link>
          </div>
          <div class="blog-button-bar">
            <b-button
              v-if="this.state.previousPage"
              class="blog-post-button"
              label="Previous Page"
              type="is-light"
              size="is-small"
              @click="previousPage"
            />
            <b-button
              v-if="this.state.nextPage"
              class="blog-post-button"
              label="Next Page"
              type="is-light"
              size="is-small"
              @click="nextPage"
            />
          </div>
        </div>
        <div class="tile is-2 is-vertical"></div>
        <div class="tile is-4 is-vertical notification vert-tile-custom">
          <BlogAboutMe />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>